<template lang="pug">
.matrix-row.circle
  matrix-gap-bottom-list-item(
    v-for="(employee, index) in bottomLabels",
    :key="index",
    :data="mutationData(employee)"
  )
</template>

<script>
import MatrixGapBottomListItem from './MatrixGapBottomListItem'

export default {
  name: 'MatrixGapBottomList',

  props: {
    bottomLabels: Array,
    isViewSwapped: Boolean
  },

  components: {
    MatrixGapBottomListItem
  },

  methods: {
    mutationData ({ gaps }) {
      return !this.isViewSwapped
        ? {
            achieved: gaps.achievedLevelsCount,
            required: gaps.requiredLevelsCount,
            gap: gaps.levelGap
          }
        : {
            achieved: gaps.achievedSkillsCount,
            required: gaps.requiredSkillsCount,
            gap: gaps.skillGap
          }
    }
  }
}
</script>

<style></style>
