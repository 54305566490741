<template lang="pug">
  .matrix-cell.matrix-cell--column
    .matrix-item
          .v-donut
            span {{ data.achieved }}
    .matrix-item
          .v-donut
            span {{ data.required }}
    .matrix-item
          .v-donut
            span {{ data.gap }}
</template>

<script>
export default {
  name: 'MatrixGapBottomListItem',

  props: {
    data: Object
  }
}
</script>

<style lang="scss" scoped>
.matrix{
  &-item{
    &:first-child{
      color: $body-text-2;
    }
    &:nth-last-child(2){
      color: $color-orange;
    }
    &:last-child {
      color: $color-dark-green;
      .v-donut{
        border-radius: 50%;
        background-color: $secondary-light-green;
      }
    }
    &:not(:last-child){
      margin-bottom: 16px;
    }
    span{
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
    }
  }
}
</style>
